.grid-container-main {
  display: grid;
  grid-template-columns: 60px;
  grid-template-rows: 1fr;
  /* grid-gap: 1px 1px; */
  /* gap: 1px 1px; */
  grid-template-areas: 'Sidebar Components';
}
.Sidebar {
  grid-area: Sidebar;
  width: 60px;
  z-index: 15;
}
.Sidebar li {
  text-align: center;
}
.Components {
  grid-area: Components;
}
